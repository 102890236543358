<template>
  <div class="docs">
    <h1>This is docs page</h1>
  </div>
</template>

<script>

export default {
  name: 'Docs'
}
</script>

<style lang="scss">
</style>
